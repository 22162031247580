
  export default {
    name: 'PickableItem',
    functional: true,
    components: {},
    props: {
      title: {
        type: String
      },
      target: {
        type: String
      },
      image: {
        type: String,
        required: true
      }
    }
  }
