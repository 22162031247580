
  import Row from '@/components/layout/Row'

  export default {
    name: 'SupportServiceList',
    functional: true,
    components: { Row },
    props: {},
    items () {
      return [
        {
          title: 'support_service_offer',
          imgLink: '/img/icons/icon_service-cart.svg'
        }, {
          title: 'support_service_loyalty',
          imgLink: '/img/icons/icon_service-badge.svg'
        }, {
          title: 'support_service_shipping',
          imgLink: '/img/icons/icon_service-car.svg'
        }, {
          title: 'support_service_warranty_claim',
          imgLink: '/img/icons/icon_service-heart.svg'
        }
      ]
    }
  }
