import { render, staticRenderFns } from "./Homepage.vue?vue&type=template&id=57f5e2f0"
import script from "./Homepage.vue?vue&type=script&lang=js"
export * from "./Homepage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/localizations/partials/Homepage.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fworkspace%2Fpages%2FHomepage.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DelayHydration: require('/workspace/node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue').default})
