
  import PickableItem from '@/components/layout/list/PickableList/PickableItem'

  const Button = () => import('@/components/general/elements/Button')

  export default {
    name: 'PickableList',
    functional: true,
    components: { PickableItem, Button },
    inject: ['getLoc'],
    props: {
      value: Boolean,
      title: {
        type: String
      },
      items: {
        type: Array,
        required: true
      }
    },
    limitedItems (value, items) {
      let limited = [...items]

      if (!value) {
        limited = limited.splice(0, 10)
      }

      return limited
    },
    actionClick (value, listeners) {
      if ('input' in listeners) {
        listeners.input(!value)
      }
    }
  }
