
  import OversizedLayer from '@/components/general/elements/Product/OversizedLayer'

  export default {
    name: 'FoilLayer',
    components: { OversizedLayer },
    props: {
      value: Boolean,
      url: String,
      alt: String,
      oversized: Boolean,
      rotated: Boolean,
      centered: Boolean,
      rounded: Boolean,
      width: String,
      height: String,
      lazy: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    },
    computed: {
      layerClass (vm) {
        return {
          'layer-wrapper': true,
          'overlay-foil': vm.value,
          'layer-centered': vm.centered,
          'corners-rounded': vm.rounded
        }
      }
    },
    methods: {}
  }
