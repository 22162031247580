
  export default {
    name: 'ImageBanner',
    functional: true,
    props: {
      content: {
        type: String
      },
      backgroundSmall: {
        type: String,
        default: null
      },
      background: {
        type: String,
        default: ''
      },
      link: String,
      navigationPage: String
    },
    data () {
      return {}
    },
    componentType (link, navPage) {
      return (link || navPage) ? 'nuxt-link' : 'div'
    },
    wrapperConfig (link, navPage) {
      const config = {}

      if (link) {
        config.to = link
      } else if (navPage) {
        config.to = navPage
      }

      return config
    }
  }
