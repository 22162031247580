
  import { mapGetters } from 'vuex'
  import map from 'lodash/map'
  import DelayHydration from 'nuxt-delay-hydration/dist/runtime/components/DelayHydration'

  import Button from '~/components/general/elements/Button'
  import InfiniteSwiper from '~/components/general/swiper/InfiniteSwiper'

  import Article from '~/database/models/Article'
  import ListItem from '~/components/layout/list/ListItem'
  import ImageBanner from '~/components/general/elements/ImageBanner'
  import SupportServiceList from '~/components/layout/static/SupportServiceList'
  import PickableList from '~/components/layout/list/PickableList/PickableList'
  import { getCatalogMockData, getMetaMockData } from '~/services/network/utils/helper.convenience.axios'
  import NavigationPage from '~/database/models/NavigationPage'
  import CMSBannerType from '@/database/models/CMSBannerType'

  export default {
    components: {
      Button,
      ListItem,
      ImageBanner,
      InfiniteSwiper,
      SupportServiceList,
      PickableList,
      DelayHydration
    },
    inject: ['getLoc'],
    async asyncData ({ store }) {
      const [banners, homepageTitles, featuredProducts] = await Promise.all([
        getMetaMockData({
          id: 'banners', query: {
            banner_type: [CMSBannerType.homepageMain, CMSBannerType.homepageContent, CMSBannerType.homepageSide].join(',')
          }
        }),
        getMetaMockData({ id: 'homepage-titles' }),
        getCatalogMockData({ id: 'featured-products' }),
      ])

      await store.dispatch('banner/getBanners', {
        mainMobile: banners.data.filter(banner => banner.banner_type === CMSBannerType.homepageMain),
        mainDesktop: banners.data.filter(banner => banner.banner_type === CMSBannerType.homepageMain),
        side: banners.data.filter(banner => banner.banner_type === CMSBannerType.homepageSide),
        content: banners.data.filter(banner => banner.banner_type === CMSBannerType.homepageContent),
      })
      await store.dispatch('banner/getHomepageTitles', {mock: homepageTitles.data})
      await store.dispatch('catalog/getFeaturedProducts', {
        newItems: featuredProducts.data.filter(product => product.featured_type === 'NEW'),
      })

      return {
        featuredNew: featuredProducts.data.filter(product => product.featured_type === 'NEW'),
        featuredPresale: featuredProducts.data.filter(product => product.featured_type === 'PRESALE'),
        featuredMonthly: featuredProducts.data.filter(product => product.featured_type === 'MONTHLY')
      }
    },
    data () {
      return {
        pickerUnwrapped: false,
        isLoading: false,
        featuredNew: [],
        featuredPresale: [],
        featuredMonthly: []
      }
    },
    head () {
      const oThis = this
      const hasMainBanners = this.hasMainBanners

      return {
        title: `Najada.games - ${this.$t('page_homepage_title')}`,
        meta: [
          {
            hid: 'og:title',
            property: 'og:title',
            content: 'Najáda'
          },
        ],
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: hasMainBanners ? oThis.getLoc(this.mainBanners[0].imageUrlMobileLoc) : '',
            media: '(max-width: 767px)'
          },
          {
            rel: 'preload',
            as: 'image',
            href: hasMainBanners ? oThis.getLoc(this.mainBanners[0].imageUrlLoc) : '',
            media: '(min-width: 768px)'
          },
          ...map(this.sideBanners, banner => {
            return {
              rel: 'preload',
              as: 'image',
              href: oThis.getLoc(banner.imageUrlLoc),
              media: '(min-width: 768px)'
            }
          })
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              name: 'Najáda',
              email: 'info@najada.cz'
            }
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        mainBanners: 'banner/homepageMain',
        sideBanners: 'banner/homepageSideTwo',

        contentBanners: 'banner/homepageContent',
        titles: 'banner/homepageTitles',

        getMenu: 'menu/getMenu'
      }),

      hasMainBanners (vm) {
        return vm.mainBanners.length > 0
      },
      hasContentBanner (vm) {
        return vm.contentBanners.length > 0
      },
      mainContentBanner (vm) {
        return vm.contentBanners[0]
      },

      newNavPage(vm) {
        return new NavigationPage(vm.getMenu('new'))
      },
      saleNavPage(vm) {
        return new NavigationPage(vm.getMenu('discounted'))
      },
      presaleNavPage(vm) {
        return new NavigationPage(vm.getMenu('presale'))
      }
    },
    created () {
      this.featuredMonthly = map(this.featuredMonthly, item => new Article(item))
      this.featuredPresale = map(this.featuredPresale, item => new Article(item))
      this.featuredNew = map(this.featuredNew, item => new Article(item))
    }
  }
