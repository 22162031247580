
  import isObject from 'lodash/isObject'
  import { mapGetters } from 'vuex'

  export default {
    name: 'NLink',
    props: {
      to: {
        type: [Object, String],
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        language: 'general/getLanguage'
      }),
      route(vm) {
        let route = vm.to

        if(isObject(route)) {
          route = {...route}
          if(!route.name.includes('__')) {
            route.name = `${route.name}__${vm.language}`
          }
        }

        return route
      }
    }
  }
